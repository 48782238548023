import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import MainCustomDrawer from "./drawer/MainCustomDrawer";
import { navBarHeight } from "../lib/theme";

type CustomPageWrapperProps = {
  children?: React.ReactNode;
};
function CustomPageWrapper(props: CustomPageWrapperProps) {
  const { children } = props;
  return (
    <Box
      id="CustomPageWrapper"
      sx={{
        display: "flex",
        minHeight: "100vh",
        height: `calc(100vh - ${navBarHeight}px)`
      }}
    >
      <MainCustomDrawer />
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexFlow: "column"
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

export default CustomPageWrapper;
