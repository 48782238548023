import { useSelector } from "react-redux";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";
import { mobileWidth } from "../../lib/theme";

import { updateOpenSiteView } from "./siteSlice";
import {
  getCurrentOpenSiteView,
  getPendingConfigurationItemsList,
  getSitetoDuplicateOrcreateInfo
} from "../../redux/selectors/siteSelectors";

import DuplicateSiteModal from "../duplicate/DuplicateSiteModal";
import DuplicateIcon from "../../assets/icons/duplicate.svg";
import AuditLogsIcon from "../../assets/icons/audit_logs.svg";
import { useAppDispatch } from "../../store";
import usePostDuplicateOrCreateSiteHook from "../../hooks/usePostDuplicateOrCreateSiteHook";
import { LoadingSpinner } from "./ConfigurationItems";
import {
  getEnvIdsListFromSelectedConfigurationItems,
  getConfigurationItemsFromPendingChanges
} from "../../lib/siteHelpers";
import siteApi, {
  useGetSitesListByClientQuery,
  useLazyEditSiteDetailsQuery
} from "../../redux/api/site";

const HeaderMainContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  padding: "5px 15px 5px 15px"
}));

const CloseAuditLogPageIcon = styled(CloseIcon)(({ theme }) => ({
  height: 25,
  width: 25,
  position: "relative",
  bottom: 18,
  left: 20,
  color: theme.palette.black.main
}));

const HeaderButton = styled(IconButton)(({ theme }) => ({
  height: 40,
  width: 40,
  margin: "5px 0px 0px 15px",
  borderRadius: "6px",
  padding: 10,
  gap: 10,
  backgroundColor: "#00000014",
  color: theme.palette.black.main
}));

type HeaderPropsType = {
  shouldRenderPendingChanges: boolean;
};

const Header = (props: HeaderPropsType) => {
  const { shouldRenderPendingChanges } = props;
  const [isDuplicateSiteOpen, setIsDuplicateSiteOpen] =
    useState<boolean>(false);

  const selectedDrawersData = useSelector(getSelectedDrawersData);
  const { data: siteListByClient = [] } = useGetSitesListByClientQuery(
    {
      clientId: selectedDrawersData?.client?.id || ""
    },
    {
      skip: !selectedDrawersData?.client?.id
    }
  );

  const openCurrentSiteView = useSelector(getCurrentOpenSiteView);
  const pendingConfigurationChangesList = useSelector(
    getPendingConfigurationItemsList
  );
  const [
    updateSiteConfigurationsItemsDetails,
    { isFetching: submitConfigItemsChangesLoading }
  ] = useLazyEditSiteDetailsQuery();
  const siteToDuplicateOrCreate = useSelector(getSitetoDuplicateOrcreateInfo);
  const isInNewSiteCreatingMode =
    !!siteToDuplicateOrCreate.generalSiteInformation?.siteId;

  const { isLoading, submitSiteToDuplicateOrCreateChanges } =
    usePostDuplicateOrCreateSiteHook(
      siteToDuplicateOrCreate,
      pendingConfigurationChangesList,
      selectedDrawersData.client?.id
    );

  const siteName = selectedDrawersData.site?.name || "";
  const isAuditLogOpen = openCurrentSiteView === "AuditLog";
  const title = isAuditLogOpen
    ? `${siteName} Audit Logs`
    : `${siteName} Configuration`;
  const isMobile = useMediaQuery(mobileWidth);
  const dispatch = useAppDispatch();
  const siteViewActions = [
    {
      icon: (
        <img
          src={DuplicateIcon}
          height={25}
          width={25}
          alt="duplicate-site-button-icon"
        />
      ),
      name: "Duplicate Site",
      onClickBtn: () => {
        setIsDuplicateSiteOpen(true);
      }
    },
    {
      icon: (
        <img
          src={AuditLogsIcon}
          height={25}
          width={25}
          alt="audit-logs-button-icon"
        />
      ),
      name: "Audit Logs",
      onClickBtn: () => {
        dispatch(updateOpenSiteView("AuditLog"));
      }
    }
  ];

  const submitEditChanges = async () => {
    const selectedSiteGeneralSiteInformation = siteListByClient?.find(
      (site) => site.siteId === selectedDrawersData?.site?.id
    );

    if (!selectedSiteGeneralSiteInformation) return;
    const configurationItems = getConfigurationItemsFromPendingChanges(
      pendingConfigurationChangesList
    );
    const selectedEnvironmentsListFromConfigurationValues =
      getEnvIdsListFromSelectedConfigurationItems(configurationItems);

    const editFields: EditSiteConfigurationItemsBody = {
      configurationItems,
      canDeleteSite: false,
      environmentIds: selectedEnvironmentsListFromConfigurationValues,
      site: {
        ...selectedSiteGeneralSiteInformation,
        address_1: selectedSiteGeneralSiteInformation?.address1
      }
    };

    await updateSiteConfigurationsItemsDetails({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      editFields,
      siteId: selectedSiteGeneralSiteInformation.siteId
    });
    dispatch(siteApi.util.invalidateTags(["site"]));
  };

  const handleSubmitPendingChanges = async (): Promise<void> => {
    if (isInNewSiteCreatingMode) {
      submitSiteToDuplicateOrCreateChanges();
    } else {
      await submitEditChanges();
    }
  };

  return (
    <HeaderMainContainer data-testid="main-header">
      <DuplicateSiteModal
        isModalOpen={isDuplicateSiteOpen}
        handleModalClose={() => {
          setIsDuplicateSiteOpen(!isDuplicateSiteOpen);
        }}
      />
      <Box
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <Typography
          fontSize={isMobile ? 18 : 28}
          fontWeight="bold"
          data-testid="site-header-title"
        >
          {title}
        </Typography>
        {!isAuditLogOpen && !isInNewSiteCreatingMode && (
          <Box
            sx={{
              display: "flex"
            }}
          >
            {siteViewActions.map((action) => (
              <Tooltip title={action.name} key={`button-${action.name}`}>
                <HeaderButton
                  onClick={action.onClickBtn}
                  id={`header-button-${action.name}`}
                  disableRipple
                >
                  {action.icon}
                </HeaderButton>
              </Tooltip>
            ))}
          </Box>
        )}
      </Box>
      {isAuditLogOpen && !isInNewSiteCreatingMode && (
        <IconButton
          aria-label="arrow-circle-btn"
          disableRipple
          onClick={() => {
            dispatch(updateOpenSiteView("SiteDetails"));
          }}
        >
          <CloseAuditLogPageIcon />
        </IconButton>
      )}
      {!isAuditLogOpen && shouldRenderPendingChanges && (
        <Button
          sx={{
            width: 200,
            height: 35,
            padding: "10px"
          }}
          color="error"
          variant="contained"
          disabled={isLoading || submitConfigItemsChangesLoading}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSubmitPendingChanges}
        >
          {isLoading ? (
            <LoadingSpinner size={25} color={"#e8e8e8"} />
          ) : (
            "Submit changes"
          )}
        </Button>
      )}
    </HeaderMainContainer>
  );
};

export default Header;
