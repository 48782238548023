import { Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

type TextInputPropsType = {
  inputValue: string | number;
  readOnly?: boolean;
  isDisabled?: boolean;
  required: boolean;
  dataType?: string;
  onInputChange?: (value: string | number) => void;
  isPartOfPendingChangesList?: boolean;
  min?: number | null;
  max?: number | null;
  textLengthLimit?: number | null;
  setValidationError?: (customValidationError: string) => void;
  inputWidth?: number;
};

const ItemTextField = styled(TextField)(() => ({
  display: "flex",
  borderRadius: 6,
  fontSize: 14,
  border: 1,
  minHeight: 45,
  minWidth: 200,
  "& .MuiOutlinedInput-root": {
    padding: 0,
    margin: 0
  }
}));

const TextInput = (props: TextInputPropsType) => {
  const {
    inputValue,
    readOnly,
    required,
    dataType,
    isDisabled = false,
    onInputChange,
    isPartOfPendingChangesList = false,
    min,
    max,
    textLengthLimit,
    setValidationError,
    inputWidth
  } = props;
  const inputType = dataType === "int" ? "number" : "text";
  const isNumberType = inputType === "number";
  const warningColor = isPartOfPendingChangesList ? "warning.dark" : "#E2E2E2";

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (readOnly || !onInputChange) return;

    const inputValue = isNumberType ? Number(e.target.value) : e.target.value;

    if (
      isNumberType &&
      min !== undefined &&
      min !== null &&
      max !== undefined &&
      max !== null
    ) {
      const isWithinBounds =
        Number(inputValue) >= min && Number(inputValue) <= max;

      if (isWithinBounds) {
        onInputChange(inputValue);
      } else if (setValidationError) {
        setValidationError(`Value needs to be between ${min} and ${max}`);
      }
    } else if (
      typeof inputValue === "string" &&
      textLengthLimit !== null &&
      textLengthLimit !== undefined
    ) {
      if (inputValue.length <= textLengthLimit) {
        onInputChange(inputValue);
      } else if (setValidationError) {
        setValidationError(
          `Value cannot exceed ${textLengthLimit} characters.`
        );
      }
    } else {
      onInputChange(inputValue);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        padding: "0px"
      }}
    >
      <ItemTextField
        value={inputValue}
        type={inputType}
        required={required}
        disabled={isDisabled || readOnly}
        multiline={!isNumberType}
        inputProps={{
          "aria-label": "number-text-input-field",
          style: {
            padding: "10px 15px 10px 15px"
          }
        }}
        sx={{
          width: inputWidth || 250,
          minHeight: 45,
          "& .MuiOutlinedInput-root": {
            backgroundColor: isDisabled ? "gray.light" : "white.main",
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: isPartOfPendingChangesList ? "3px" : "1px",
              borderRadius: isPartOfPendingChangesList ? "10px" : "inherit"
            },
            "&.Mui-disabled fieldset": {
              borderColor: warningColor
            }
          },
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
            opacity: isDisabled ? 0.3 : 1
          }
        }}
        onChange={handleInputChange}
      />
    </Box>
  );
};

export default TextInput;
