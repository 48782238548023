import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  ThemeOptions
} from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    gray: PaletteColor;
    lightBlue: PaletteColor;
    darkBlue: PaletteColorOptions;
    lightGreen: PaletteColor;
    lightRed: PaletteColor;
    lightYellow: PaletteColor;
    white: PaletteColor;
    black: PaletteColor;
  }

  interface PaletteOptions {
    gray: PaletteColorOptions;
    lightBlue: PaletteColorOptions;
    lightGreen: PaletteColorOptions;
    darkBlue: PaletteColorOptions;
    lightRed: PaletteColorOptions;
    lightYellow: PaletteColorOptions;
    white: PaletteColorOptions;
    black: PaletteColorOptions;
  }
}

const themeObj: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#276FEC",
      dark: "#185BD1",
      light: "#E4ECFF",

      contrastText: "#ffffff"
    },
    secondary: {
      main: "#f0f0f0",
      dark: "#e8e8e8",
      contrastText: "#000"
    },
    text: {
      primary: "#212121",
      secondary: "#ebebeb",
      disabled: "#f1f1f1"
    },
    error: {
      main: "#E11B22",
      dark: "#C91218",
      light: "#ffffff"
    },
    info: {
      main: "#15844B",
      dark: "#0E733F",
      light: "#ffffff"
    },
    warning: {
      main: "#FFC043",
      dark: "#BA8A2C",
      light: "#FFF2D9",
      contrastText: "#000000DE"
    },
    success: {
      main: "#15844B",
      dark: "#0E733F",
      light: "#ffffff"
    },
    background: {
      default: "#f0f0f0",
      paper: "#ffffff"
    },
    divider: "rgba(0,0,0,0.2)",
    // custom colors
    lightBlue: {
      main: "#E4ECFF",
      contrastText: "#2260D3"
    },
    lightGreen: {
      main: "#E6F2ED",
      contrastText: "#137643"
    },
    lightRed: {
      main: "#FFEFED",
      contrastText: "#D2191F"
    },
    lightYellow: {
      main: "#FFF2D9",
      contrastText: "#000000DE"
    },
    darkBlue: { main: "#2260D3" },
    gray: {
      light: "#f7f7f7",
      main: "rgba(0, 0, 0, 0.6)",
      dark: "#000000DE"
    },
    white: {
      main: "#ffffff",
      contrastText: "#000000"
    },
    black: {
      main: "#000000",
      contrastText: "#ffffff"
    }
  },
  shape: {
    borderRadius: 6
  },
  typography: {
    fontFamily: "CircularXX"
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: "contained"
      },
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: 400,
          borderRadius: "6px",
          textTransform: "none",
          "&.Mui-disabled": {
            pointerEvents: "unset",
            cursor: "not-allowed"
          },
          "&.MuiButton-containedPrimary.Mui-disabled": {
            background: "#BDD3FF",
            color: "white"
          },
          "&:focus-visible": {
            outline: `4px solid ${theme.palette.darkBlue.main}`
          }
        }),
        contained: {
          "&:focus-visible": {
            outlineOffset: "2px"
          }
        }
      }
    },
    MuiLink: {
      defaultProps: {
        style: {
          color: "rgba(0, 0, 0, 0.87)",
          fontFamily: "CircularXX",
          borderColor: "rgba(0,0,0,0.2)"
        }
      }
    },
    MuiAppBar: {
      defaultProps: {
        style: {
          backgroundColor: "#f0f0f0",
          color: "rgba(0, 0, 0, 0.87)",
          borderBottom: "1px solid rgba(0,0,0,0.2)",
          minHeight: 50
        },
        elevation: 0
      }
    },
    MuiPaper: {
      defaultProps: {
        style: {
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 3px 1px",
          border: "1px solid rgba(0,0,0,0.2)"
        }
      }
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
        sx: {
          pl: 4,
          borderRadius: 1,
          margin: 1,
          textAlign: "center",
          color: "#00000099",
          "&:hover": {
            backgroundColor: "#E4ECFF",
            color: "#2260D3"
          }
        }
      }
    },
    MuiListItemText: {
      defaultProps: {
        sx: {
          "& span": {
            fontSize: 14,
            fontWeight: 500
          }
        }
      }
    },
    MuiSvgIcon: {
      defaultProps: {
        sx: {
          color: "#212121",
          height: 25,
          width: 25
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        sx: {
          "& .MuiOutlinedInput-root": {
            padding: "15px 10px 8px 10px"
          }
        }
      }
    }
  }
};

export const mobileWidth = `(max-width: 600px)`;

export const drawerWidth = 216;
export const navBarHeight = 64;

export const tabletWidth = "(max-width: 1300px)";
export const desktopWidth = "(max-width: 1800px)";
export const tabletPortrait = `(max-width: 800px)`;

export const mainTheme = createTheme(themeObj);
