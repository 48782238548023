import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { getPendingConfigurationItemsList } from "../../redux/selectors/siteSelectors";
import PendingConfigurationItemValuesChange from "./PendingConfigurationItemValuesChange";
import {
  deletePendingChangesListEntry,
  SiteToDuplicateOrCreateType
} from "./siteSlice";
import { useAppDispatch } from "../../store";
import Loading from "../Loading";
import Stack from "@mui/material/Stack";

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowX: "hidden",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  background: theme.palette.secondary.dark,
  border: `1px solid #d8d8d8`,
  boxShadow: "rgba(0, 0, 0, 0.30) 0px 0px 3px 1px",
  gridColumn: "3/4",
  padding: "10px"
}));

const MainPendingChangesListContainer = styled(Stack)(() => ({
  flexDirection: "column",
  gap: "10px"
}));

const MainHeaderContainer = styled(Stack)(() => ({
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row"
}));
type PendingChangesListProps = {
  siteToDuplicateOrCreate?: SiteToDuplicateOrCreateType;
};
function PendingChangesList(props: PendingChangesListProps) {
  const { siteToDuplicateOrCreate } = props;
  const { isSiteCreationPending = false } = siteToDuplicateOrCreate || {};

  const pendingConfigurationChangesList = useSelector(
    getPendingConfigurationItemsList
  );
  const dispatch = useAppDispatch();

  return (
    <MainContainer>
      {isSiteCreationPending ? (
        <Loading numberOfRows={15} />
      ) : (
        Object.keys(pendingConfigurationChangesList).map(
          (pendingConfigurationItemId) => {
            const pendingChange =
              pendingConfigurationChangesList[pendingConfigurationItemId];

            const {
              sectionName,
              configurationName,
              configurationItemId,
              sectionId,
              currentConfigurationItemValues,
              originalConfigurationItemValues,
              dataType,
              isPartOfSiteDuplicationOrCreation,
              validationRules
            } = pendingChange;

            return (
              <MainPendingChangesListContainer
                key={`pending-change-${sectionId}-${configurationItemId}`}
                data-testid="pending-change-item"
              >
                <MainPendingChangesListContainer>
                  <MainHeaderContainer>
                    <Typography
                      sx={{
                        fontSize: 20
                      }}
                      data-testid={`section-name`}
                    >
                      {sectionName}
                    </Typography>
                    {!isPartOfSiteDuplicationOrCreation && (
                      <IconButton
                        data-testid="remove-pending-change-item-btn"
                        aria-label="remove-pending-change-item-btn"
                        onClick={() => {
                          dispatch(
                            deletePendingChangesListEntry(
                              pendingConfigurationItemId
                            )
                          );
                        }}
                        sx={{
                          marginRight: "10px"
                        }}
                      >
                        <CloseIcon
                          sx={{
                            height: 25,
                            width: 25,
                            color: "black.main"
                          }}
                        />
                      </IconButton>
                    )}
                  </MainHeaderContainer>
                  <Typography
                    sx={{
                      fontSize: 14
                    }}
                    data-testid={`config-item-name-${configurationItemId}`}
                  >
                    {configurationName}
                  </Typography>
                  {!isPartOfSiteDuplicationOrCreation ? (
                    <PendingConfigurationItemValuesChange
                      pendingConfigurationIteValuesListChanges={
                        originalConfigurationItemValues
                      }
                      configurationName={configurationName}
                      type="original"
                      dataType={dataType}
                      validationRules={validationRules}
                    />
                  ) : null}
                  {!isPartOfSiteDuplicationOrCreation && (
                    <Divider
                      sx={{
                        margin: "5px 0px",
                        opacity: 0.8
                      }}
                    />
                  )}
                  <PendingConfigurationItemValuesChange
                    pendingConfigurationIteValuesListChanges={
                      currentConfigurationItemValues
                    }
                    configurationName={configurationName}
                    type="new"
                    dataType={dataType}
                    validationRules={validationRules}
                  />
                </MainPendingChangesListContainer>
                <Divider
                  sx={{
                    margin: "10px 0px"
                  }}
                />
              </MainPendingChangesListContainer>
            );
          }
        )
      )}
    </MainContainer>
  );
}

export default PendingChangesList;
