import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

import { useGetSiteConfigurationsListQuery } from "../../redux/api/site";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";

import SiteConfiguration, {
  SectionGroupingMenuTitle
} from "./SiteConfiguration";
import Loading from "../Loading";
import { SiteToDuplicateOrCreateType } from "./siteSlice";

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: 12,
  borderRadius: 10,
  background: theme.palette.background.paper,
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  borderRight: "1px solid rgba(0,0,0,0.2)",
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0
}));

const MainMenuSectionContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "0.625rem"
}));

type SiteConfigurationsProps = {
  siteToDuplicateOrCreate: SiteToDuplicateOrCreateType | null;
};
function SiteConfigurations(props: SiteConfigurationsProps) {
  const { siteToDuplicateOrCreate } = props;
  const {
    siteConfigurationsByMenuType,
    generalSiteInformation,
    isSiteCreationPending
  } = siteToDuplicateOrCreate || {};
  const { formattedConfigurationsByMainGroupings: newSiteMenuGroupings } =
    siteConfigurationsByMenuType || {};
  const isInNewSiteCreatingMode = !!generalSiteInformation?.siteId;
  const selectedDrawersData = useSelector(getSelectedDrawersData);
  const siteId = selectedDrawersData.site?.id;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data, isLoading } = useGetSiteConfigurationsListQuery(
    {
      siteId: siteId || ""
    },
    {
      skip: !siteId || isInNewSiteCreatingMode,
      refetchOnMountOrArgChange: true
    }
  );
  const menuItemsData = isInNewSiteCreatingMode
    ? siteConfigurationsByMenuType
    : (data as unknown as SiteConfigurationResponseType);
  const mainMenuSections =
    isInNewSiteCreatingMode && newSiteMenuGroupings
      ? Object.keys(newSiteMenuGroupings)
      : menuItemsData
        ? Object.keys(menuItemsData.formattedConfigurationsByMainGroupings)
        : [];
  const shouldRenderLoadingUI = isLoading || isSiteCreationPending;
  return (
    <MainContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
        data-testid="site-configurations-menu"
      >
        {!shouldRenderLoadingUI && menuItemsData ? (
          mainMenuSections.length ? (
            mainMenuSections.map((configurationTypeId: string) => {
              const sectionItem =
                menuItemsData.formattedConfigurationsByMainGroupings[
                  configurationTypeId
                ];
              const sectionConfigurationItems: SiteConfigurationMenuItem[] =
                sectionItem.configurations;

              const sectionName =
                menuItemsData.allConfigurationsMenuTypes[configurationTypeId]
                  .configurationTypeName || "";
              return (
                <MainMenuSectionContainer
                  key={`main-site-section-${configurationTypeId}`}
                  data-test-id={`main-site-section-${configurationTypeId}`}
                >
                  <SectionGroupingMenuTitle data-testid="section-title">
                    {sectionName}
                  </SectionGroupingMenuTitle>
                  {sectionConfigurationItems.map((section) => {
                    return (
                      <SiteConfiguration
                        key={`site-section-${section.configurationId}`}
                        sectionData={section}
                        allConfigurationsMenuTypes={
                          menuItemsData.allConfigurationsMenuTypes
                        }
                        isInNewSiteCreatingMode={isInNewSiteCreatingMode}
                      />
                    );
                  })}
                  <Divider sx={{ borderBottomWidth: 2, margin: "15px 0px" }} />
                </MainMenuSectionContainer>
              );
            })
          ) : (
            <MainMenuSectionContainer
              sx={{
                padding: "0.5rem",
                maxWidth: 200
              }}
            >
              <Typography fontSize={"0.875rem"}>
                No configuration groupings found.
              </Typography>
            </MainMenuSectionContainer>
          )
        ) : (
          <Loading numberOfRows={15} width={220} />
        )}
      </Box>
    </MainContainer>
  );
}

export default SiteConfigurations;
