import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import useMediaQuery from "@mui/material/useMediaQuery";

import autostoreLogo from "../assets/images/as-logo.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { resetDrawerStructure } from "./drawer/mainCustomDrawerSlice";
import { getUserProfile } from "../redux/selectors/userSelectors";
import { resetUserState } from "./login/userSlice";
import { clientConfigApi } from "../redux/api";
import { mobileWidth, navBarHeight } from "../lib/theme";
import { useAppDispatch } from "../store";

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userProfile = useSelector(getUserProfile);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const isUserLoggedIn = !!userProfile?.accessToken;

  const navigateToHomePage = () => {
    if (isUserLoggedIn)
      dispatch(
        resetDrawerStructure(() => {
          navigate("/");
        })
      );
  };

  const logoutUser = () => {
    dispatch(resetUserState());
    dispatch(clientConfigApi.util.resetApiState());
  };

  const isMobile = useMediaQuery(mobileWidth);
  return (
    <Box
      data-testid="navbar"
      sx={{ display: "flex", maxWidth: 480 }}
      displayPrint="none"
    >
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          height: navBarHeight
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <img
            src={autostoreLogo}
            alt="partner logo"
            style={{
              height: isMobile ? 20 : 30,
              background: "transparent",
              marginBottom: isMobile ? 5 : 10,
              cursor: "pointer"
            }}
            onClick={navigateToHomePage}
          />
          {isUserLoggedIn && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <Typography
                fontSize={isMobile ? 14 : 16}
                fontWeight={300}
                data-testid="user-email"
              >
                {userProfile.email}
              </Typography>
              <IconButton
                aria-label="logout-btn"
                disableRipple
                onClick={logoutUser}
                aria-description="Logout"
              >
                <LogoutIcon
                  sx={{
                    height: isMobile ? 20 : 30,
                    width: isMobile ? 20 : 30,
                    marginLeft: `${isMobile ? 2 : 10}px`
                  }}
                />
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navbar;
