/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RecentSearchType } from "../home/homepageSlice";
import {
  SubmitDuplicateSiteInformationType,
  submitDuplicateSiteRequiredFields
} from "../site/siteSlice";
import siteApi from "../../redux/api/site";

export type SelectedSingleDrawerDataType = {
  name: string;
  id: string;
  salesforceId: string;
};
export type SelectedDrawersDataType = {
  [drawerType in DrawerType]: SelectedSingleDrawerDataType | null;
};
export type UIStateType = {
  drawerState: DrawerState;
  currentSelectedOpenDrawerType: DrawerType | null;
  selectedDrawersData: SelectedDrawersDataType;
};

export type ToggleSelectedDrawerType = {
  drawerType: DrawerType;
  isDrawerOpenValue: boolean;
};

export type OnDrawerMenuBtnClickType = {
  drawerType: DrawerType;
  selectedDrawerOption: SelectedSingleDrawerDataType;
  nextDrawerTypeToOpen: DrawerType | null;
};

type ResetDrawerStructureType = {
  onResetFunc?: () => void;
};

const initialState: UIStateType = {
  drawerState: {
    partner: {
      isOpen: false,
      shouldDisplay: false,
      isDisabled: true
    },
    client: {
      isOpen: false,
      shouldDisplay: true,
      isDisabled: false
    },
    site: {
      isOpen: false,
      shouldDisplay: true,
      isDisabled: true
    }
  },
  currentSelectedOpenDrawerType: null,
  selectedDrawersData: {
    partner: null,
    client: null,
    site: null
  }
};

const mainCustomDrawerStateSlice = createSlice({
  name: "mainCustomDrawer",
  initialState: initialState,
  reducers: {
    toggleSelectedDrawer(state, action) {
      const { drawerType, isDrawerOpenValue } =
        action.payload as ToggleSelectedDrawerType;

      state.drawerState[drawerType].isOpen = isDrawerOpenValue;

      if (isDrawerOpenValue) {
        state.currentSelectedOpenDrawerType = drawerType;
        state.selectedDrawersData[drawerType] = null;
        state.drawerState[drawerType].isDisabled = false;
      }
      if (drawerType === "partner") {
        state.selectedDrawersData.client = null;
        state.drawerState.client.isDisabled = true;
        state.selectedDrawersData.site = null;
        state.drawerState.site.isDisabled = true;
      } else if (drawerType === "client") {
        state.selectedDrawersData.site = null;
        state.drawerState.site.isDisabled = true;
      }
    },
    onDrawerOptionBtnClick(state, action) {
      const { drawerType, selectedDrawerOption, nextDrawerTypeToOpen } =
        action.payload as OnDrawerMenuBtnClickType;

      if (nextDrawerTypeToOpen) {
        state.currentSelectedOpenDrawerType = nextDrawerTypeToOpen;
        state.drawerState[drawerType].isOpen = false;
        state.drawerState[nextDrawerTypeToOpen].isOpen = true;
        state.drawerState[nextDrawerTypeToOpen].isDisabled = false;
      } else {
        state.currentSelectedOpenDrawerType = null;
        state.drawerState[drawerType].isOpen = false;
        //site will always be last drawer - need to make sure site is always accessible
        state.drawerState[drawerType].isDisabled = false;
      }
      state.selectedDrawersData[drawerType] = selectedDrawerOption;
    },
    navigateToRecentSiteSearch(state, action) {
      const { siteName, siteId, clientName, clientId } =
        action.payload as RecentSearchType;
      state.drawerState.site.isDisabled = false;
      state.drawerState.site.isOpen = false;
      state.drawerState.client.isOpen = false;
      state.currentSelectedOpenDrawerType = null;
      state.selectedDrawersData.client = {
        name: clientName,
        id: clientId,
        salesforceId: ""
      };
      state.selectedDrawersData.site = {
        name: siteName,
        id: siteId,
        salesforceId: ""
      };
    },
    resetDrawerStructure(state, action) {
      const { onResetFunc } = action.payload as ResetDrawerStructureType;
      state.drawerState = {
        partner: {
          isOpen: false,
          shouldDisplay: false,
          isDisabled: true
        },
        client: {
          isOpen: false,
          shouldDisplay: true,
          isDisabled: false
        },
        site: {
          isOpen: false,
          shouldDisplay: true,
          isDisabled: true
        }
      };
      state.currentSelectedOpenDrawerType = null;
      state.selectedDrawersData = {
        partner: null,
        client: null,
        site: null
      };
      state.currentSelectedOpenDrawerType = null;

      if (onResetFunc) onResetFunc();
    }
  },
  extraReducers: (builder) => {
    builder.addCase(submitDuplicateSiteRequiredFields, (state, action) => {
      const { addionalNewSiteInfo, pendingChanges } =
        action.payload as SubmitDuplicateSiteInformationType;
      const newSiteName =
        pendingChanges.find(
          (el) => el.configurationName.toLowerCase() === "name"
        )?.currentConfigurationItemValues[0]?.configurationValue ||
        addionalNewSiteInfo.name;
      state.selectedDrawersData.site = {
        name: newSiteName,
        id: addionalNewSiteInfo.siteId,
        salesforceId: addionalNewSiteInfo.salesforceId
      };
    });
    builder.addMatcher(
      siteApi.endpoints.postDuplicateOrCreateSite.matchFulfilled,
      (state, action: PayloadAction<SubmitDuplicateOrCreateSiteResponse>) => {
        const { site } = action.payload;
        state.selectedDrawersData.site = {
          name: site?.name || "",
          id: site?.siteId || "",
          salesforceId: site?.salesforceId || ""
        };
      }
    );
  }
});

export const {
  toggleSelectedDrawer,
  onDrawerOptionBtnClick,
  navigateToRecentSiteSearch,
  resetDrawerStructure
} = mainCustomDrawerStateSlice.actions;
export default mainCustomDrawerStateSlice.reducer;
