import { useSelector } from "react-redux";

import CustomPageWrapper from "../components/CustomPageWrapper";
import MainSiteContainer from "../components/site/MainSiteContainer";
import HomePageContent from "../components/home/HomePageContent";
import Navbar from "../components/Navbar";
import { getCurrentOpenSiteView } from "../redux/selectors/siteSelectors";
import AuditLog from "../components/auditLog/AuditLog";

function Home() {
  const openCurrentSiteView = useSelector(getCurrentOpenSiteView);
  const renderHomePageContent = () => {
    switch (openCurrentSiteView) {
      case "SiteDetails":
        return <MainSiteContainer />;
      case "AuditLog":
        return <AuditLog />;
      case "HomePage":
        return <HomePageContent />;
      default:
        return <></>;
    }
  };
  return (
    <CustomPageWrapper>
      <Navbar />
      {renderHomePageContent()}
    </CustomPageWrapper>
  );
}

export default Home;
