import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

type DeploymentStatusContentProps = {
  deploymentsByEventType: DeploymentsByEventType;
  deploymentsEventsList: string[];
  isDeploymentEventsDrawerOpen: boolean;
  refetchDeploymentStatus: () => unknown;
  isDeploymentStatusLoading: boolean;
};

const DeploymentEventTitle = styled(Typography)(() => ({
  textAlign: "center",
  margin: "5px",
  fontSize: "1.1rem"
}));

const DeploymentEventsListMainTitle = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: "1.1rem"
}));

const DeploymentEventsStatus = styled(Typography)(() => ({
  textAlign: "center",
  fontStyle: "italic",
  color: "gray.main"
}));

const RefetchDeploymentStatusBtn = styled(IconButton)(() => ({
  height: 40,
  width: 40
}));

const DeploymentsDrawerContentMainContainer = styled(Stack)(() => ({
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  gap: "10px"
}));

const DeploymentEventsSublistHeaderContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: { isCurrentDeploymentEventSublistOpen: boolean };
}>(({ extraProps, theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "10px 5px 0px 5px",
  border: `1px solid ${theme.palette.secondary.dark}`,
  backgroundColor: extraProps.isCurrentDeploymentEventSublistOpen
    ? theme.palette.secondary.main
    : theme.palette.background.paper,
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 3px 1px",
  cursor: "pointer"
}));

const DeploymentEventsSublistContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: { isLastElement: boolean };
}>(({ extraProps, theme }) => ({
  display: "grid",
  gridTemplateColumns: "40px 1fr 1fr",
  gap: "5px",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: extraProps.isLastElement
    ? "none"
    : `1px solid ${theme.palette.secondary.dark}`
}));

const DeploymentEventsContainer = styled(Collapse, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: `1px solid ${theme.palette.secondary.dark}`,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 3px 1px",
  margin: "0px 5px"
}));

function DeploymentEventsStatusContent(props: DeploymentStatusContentProps) {
  const {
    deploymentsByEventType,
    deploymentsEventsList,
    isDeploymentEventsDrawerOpen,
    refetchDeploymentStatus,
    isDeploymentStatusLoading
  } = props;
  const [showEventTypes, setShowEventTypes] = useState<{
    [deploymentId: string]: boolean;
  }>({});
  const instructions =
    "Please sync the grid and set workstations through Qubit UI if you haven't done so.";

  useEffect(() => {
    deploymentsEventsList?.forEach((deploymentEventName) => {
      setShowEventTypes((prevState) => ({
        ...prevState,
        [deploymentEventName]: false
      }));
    });
  }, [
    deploymentsEventsList,
    refetchDeploymentStatus,
    isDeploymentEventsDrawerOpen
  ]);

  const renderEventCompleteTags = (isEventTypeComplete: boolean) => (
    <Box sx={{ margin: "10px" }}>
      {isEventTypeComplete ? (
        <CheckBoxOutlinedIcon
          sx={{
            color: "success.main"
          }}
        />
      ) : (
        <ErrorOutlineIcon
          sx={{
            color: "error.main"
          }}
        />
      )}
    </Box>
  );

  const handleOpenOrCloseDeploymentStatusSublist = (
    deploymentEventName: string
  ) => {
    setShowEventTypes((prevState) => ({
      ...prevState,
      [deploymentEventName]: !prevState[deploymentEventName]
    }));
  };

  return (
    <DeploymentsDrawerContentMainContainer aria-label="deployment-status-drawer-content">
      <Typography
        fontWeight="300"
        aria-label="deployment-status-drawer-instructions"
      >
        Your new site, bins and grid have been initiated!
      </Typography>
      <Typography
        fontWeight="300"
        aria-label="deployment-status-drawer-instructions"
      >
        {instructions}
      </Typography>
      <Stack
        flexDirection="row"
        margin="10px 0px"
        alignItems="center"
        aria-label="deployment-status-drawer-centent-header"
      >
        <DeploymentEventsListMainTitle>
          Deployment Events:
        </DeploymentEventsListMainTitle>
        <Tooltip
          title={"Fetch latest changes"}
          aria-label="refetch-deployment-status-btn"
          placement="top"
        >
          <Box>
            <RefetchDeploymentStatusBtn
              disabled={isDeploymentStatusLoading}
              onClick={() => {
                void refetchDeploymentStatus();
              }}
            >
              <RefreshIcon
                sx={{
                  opacity: isDeploymentStatusLoading ? 0.5 : 1,
                  color: "black.main"
                }}
              />
            </RefetchDeploymentStatusBtn>
          </Box>
        </Tooltip>
      </Stack>
      <Box>
        {deploymentsEventsList && deploymentsEventsList?.length ? (
          deploymentsEventsList.map((deploymentEventName, index) => {
            const { deployments, isEventTypeComplete } =
              deploymentsByEventType[deploymentEventName];
            const isCurrentDeploymentEventSublistOpen =
              showEventTypes[deploymentEventName];
            return (
              <Stack flexDirection="column" key={`deployment-event-${index}`}>
                <DeploymentEventsSublistHeaderContainer
                  extraProps={{
                    isCurrentDeploymentEventSublistOpen
                  }}
                  onClick={() =>
                    handleOpenOrCloseDeploymentStatusSublist(
                      deploymentEventName
                    )
                  }
                  aria-label="deployment-events-sublist-header"
                >
                  {renderEventCompleteTags(isEventTypeComplete)}
                  <DeploymentEventTitle>
                    {deploymentEventName}
                  </DeploymentEventTitle>
                  <Icon
                    sx={{
                      height: 35,
                      width: 35
                    }}
                  >
                    {isCurrentDeploymentEventSublistOpen ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </Icon>
                </DeploymentEventsSublistHeaderContainer>
                <DeploymentEventsContainer
                  in={isCurrentDeploymentEventSublistOpen}
                  aria-label="deployment-events-sublist"
                >
                  <Stack flexDirection="column">
                    {deployments.map((deployment, index) => {
                      const { description, status, deploymentId, completed } =
                        deployment;
                      const isLastElement = deployments.length - 1 === index;

                      return (
                        <DeploymentEventsSublistContainer
                          key={`deployment-${deploymentId}`}
                          aria-label="deployment-event-details"
                          extraProps={{
                            isLastElement
                          }}
                        >
                          {renderEventCompleteTags(completed)}
                          <Typography
                            sx={{
                              textAlign: "center"
                            }}
                            aria-label="deployment-event-title"
                          >
                            {description}
                          </Typography>
                          <DeploymentEventsStatus aria-label="deployment-event-status">
                            {status}
                          </DeploymentEventsStatus>
                        </DeploymentEventsSublistContainer>
                      );
                    })}
                  </Stack>
                </DeploymentEventsContainer>
              </Stack>
            );
          })
        ) : (
          <Typography fontWeight="300">
            No deployment status events found.
          </Typography>
        )}
      </Box>
    </DeploymentsDrawerContentMainContainer>
  );
}

export default DeploymentEventsStatusContent;
