/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";

import { getEnvTagNameToDisplay } from "../../lib/siteHelpers";
import { tabletWidth } from "../../lib/theme";

import ItemInput from "../input/ItemInput";
import { SelectedConfigurationItemType } from "./ConfigurationItems";
import SelectedConfigItem from "./SelectedConfigItem";
import EditIcon from "../../assets/icons/edit.svg";
import CloseCircleIcon from "../../assets/icons/close_circle.svg";
import useValidationRulesPerEnvironment from "../../hooks/useValidationRulesPerEnvironment";
import AttentionIcon from "../../assets/icons/info.svg";
import { generateUUID } from "../../lib/helpers";
import { useGetEnvironmentsListQuery } from "../../redux/api/environments";

export const ItemInputContainer = styled(Stack)(() => ({
  flexDirection: "row",
  justifyContent: "center",
  alignSelf: "baseline",
  gap: "10px"
}));

export const ItemValuesContainer = styled(Stack)(() => ({
  flexDirection: "column",
  gap: 12
}));

export const EnvironmentTagsContainer = styled(Stack)(() => ({
  justifyContent: "center",
  flexDirection: "row",
  alignItems: "center",
  minWidth: 160,
  alignSelf: "center",
  gap: "5px"
}));

const MainContainer = styled(Grid)(() => ({
  display: "grid",
  gridTemplateColumns: "minmax(100px, 250px) 50px minmax(550px, 1fr)",
  gap: "20px",
  margin: "0px 10px",
  justifyItems: "stretch",
  "@media (max-width: 1250px)": {
    gridTemplateColumns: "45px minmax(100px, 1fr) minmax(200px, 350px)",
    gridTemplateRows: "50px 60px 1fr"
  }
}));

const ItemValueNameContainer = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 40px",
  marginTop: "8px",
  "@media (max-width: 1250px)": {
    gridColumn: "span 2"
  }
}));

export const DescriptionTooltip = (props: {
  additionalStyling?: unknown;
  description: string;
}) => {
  return (
    <Tooltip
      title={props.description}
      style={{
        position: "relative",
        ...props?.additionalStyling
      }}
      data-testid="configuration-item-description"
    >
      <img src={AttentionIcon} height={24} width={24} />
    </Tooltip>
  );
};
const EditCloseButton = (props: {
  onClickBtn: () => void;
  isConfigItemSelected: boolean;
}) => {
  const { onClickBtn, isConfigItemSelected } = props;
  return (
    <IconButton
      sx={
        !isConfigItemSelected
          ? {
              height: 42,
              width: 42,
              borderRadius: "4px",
              backgroundColor: "secondary.dark",
              ":hover": { backgroundColor: "secondary.light" },
              "& .MuiTouchRipple-child": { borderRadius: "inherit" }
            }
          : {
              padding: 0
            }
      }
      aria-label="edit-input-btn"
      disableRipple
      onClick={onClickBtn}
      data-testid={
        !isConfigItemSelected ? "edit-input-btn" : "close-edit-mode-btn"
      }
    >
      {!isConfigItemSelected ? (
        <img src={EditIcon} height={30} width={30} />
      ) : (
        <img src={CloseCircleIcon} height={42} width={42} />
      )}
    </IconButton>
  );
};

export const EnvironmentTag = styled(Box, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: {
    tagColor: string;
    height: number;
    width: number;
    isPartOfPendingChangesList: boolean;
  };
}>(({ extraProps }) => ({
  display: "flex",
  padding: "6px",
  justifyContent: "center",
  alignItems: "center",
  width: extraProps.width,
  height: extraProps.height,
  borderRadius: "999px",
  backgroundColor: extraProps.tagColor,
  color: "#000000",
  margin: "5px 5px 8px 0px",
  opacity: extraProps.isPartOfPendingChangesList ? 0.5 : 1
}));

type ConfigurationItemProps = {
  selectedSectionItem: ConfigurationItemType;
  selectedConfigurationItem: SelectedConfigurationItemType;
  saveSelectedConfigurationItemChanges: (
    pendingChange: PendingChangeType
  ) => void;
  clearSelectedConfigItem: () => void;
  updateSelectedConfigurationItem: (
    configurationItem: SelectedConfigurationItemType
  ) => void;
  isPartOfPendingChangesList?: boolean;
  isInNewSiteCreatingMode?: boolean;
};

export const renderEnvTag = (
  envName: EnvTagNamesType,
  isPartOfPendingChangesList?: boolean,
  width?: number,
  height?: number
) => {
  const getItemEnvTagsColor = (envName: EnvTagNamesType) => {
    switch (envName) {
      case "Staging":
        return "rgba(189, 211, 255, 0.6)";
      case "QA":
      case "UAT":
        return "rgba(255, 189, 189, 0.6)";
      case "Production":
      default:
        return "rgba(235, 235, 157, 0.6)";
    }
  };
  const tagColor = getItemEnvTagsColor(envName);
  const envTagNameToDisplay = getEnvTagNameToDisplay(envName);
  return (
    <EnvironmentTag
      key={`env-${envName || generateUUID()}`}
      extraProps={{
        tagColor,
        height: height || 22,
        width: width || 32,
        isPartOfPendingChangesList: isPartOfPendingChangesList || false
      }}
    >
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 400,
          textAlign: "center"
        }}
      >
        {envTagNameToDisplay}
      </Typography>
    </EnvironmentTag>
  );
};

function ConfigurationItem(props: ConfigurationItemProps) {
  const {
    selectedSectionItem,
    selectedConfigurationItem,
    updateSelectedConfigurationItem,
    clearSelectedConfigItem,
    isPartOfPendingChangesList,
    isInNewSiteCreatingMode
  } = props;
  const {
    name,
    description,
    dataType,
    configurationItemId,
    configurationValues,
    validationRules
  } = selectedSectionItem;

  const { data } = useGetEnvironmentsListQuery(undefined);
  const environmentsData = (data || {}) as EnvironmentsListType;

  const { environmentsDetailsById = {} }: EnvironmentsListType =
    environmentsData;

  const { validationRulesPerEnvironment, allAvailableEnvironmentNames } =
    useValidationRulesPerEnvironment(validationRules, environmentsDetailsById);

  const isConfigItemSelected =
    selectedConfigurationItem.configurationItemId === configurationItemId;
  const isJSONType = dataType === "json";

  const isTablet = useMediaQuery(tabletWidth);

  const onEditBtnClick = (item: ConfigurationItemType) => {
    updateSelectedConfigurationItem({
      configurationName: name,
      configurationItemId,
      originalValues: configurationValues,
      currentSelectedConfigValue: item
    });
  };

  useEffect(() => {
    clearSelectedConfigItem();
  }, []);

  return (
    <MainContainer
      data-testid="cofig-item-container"
      sx={{
        gridTemplateRows: isConfigItemSelected ? "min-content 1fr" : "1fr"
      }}
    >
      <ItemValueNameContainer>
        <Typography
          sx={{
            fontSize: 14
          }}
        >
          {name}
        </Typography>
        {!!description.length && (
          <DescriptionTooltip
            description={description}
            additionalStyling={{
              bottom: 1,
              left: 10
            }}
          />
        )}
      </ItemValueNameContainer>
      <Stack
        sx={{
          "@media (max-width: 1250px)": {
            gridRow: "2/3"
          }
        }}
      >
        {!isJSONType && !isPartOfPendingChangesList ? (
          <EditCloseButton
            isConfigItemSelected={isConfigItemSelected}
            onClickBtn={() => {
              if (isConfigItemSelected) {
                clearSelectedConfigItem();
              } else {
                onEditBtnClick(selectedSectionItem);
              }
            }}
          />
        ) : (
          <></>
        )}
      </Stack>
      <Stack
        sx={{
          flexDirection: "column",
          minWidth: 600,
          gap: "10px",
          "@media (max-width: 1250px)": {
            gridColumn: "span 2"
          }
        }}
      >
        {configurationValues.map((item: ConfigurationValue, index) => {
          const { environmentIds, configurationValue } = item;
          const currentValidationRules =
            validationRulesPerEnvironment[environmentIds[0]];

          const validationRule =
            currentValidationRules?.validationRules || null;
          const isRequired = currentValidationRules?.isRequired || false;

          return (
            <ItemInputContainer
              sx={{
                flexWrap: isTablet || isJSONType ? "wrap" : "nowrap"
              }}
              key={`environment-values-item-${configurationItemId}-${index}`}
            >
              <ItemInput
                configurationValue={configurationValue}
                dataType={dataType}
                required={isRequired}
                readOnly
                validationRules={validationRule}
                isConfigurationItemSelected={isConfigItemSelected}
                isPartOfPendingChangesList={isPartOfPendingChangesList}
                isInNewSiteCreatingMode={isInNewSiteCreatingMode}
              />
              <EnvironmentTagsContainer>
                {allAvailableEnvironmentNames.map((envName) => {
                  if (
                    environmentIds.find(
                      (envId: string) =>
                        envName ===
                        environmentsDetailsById?.[envId]?.environmentName
                    )
                  )
                    return renderEnvTag(envName);
                  return <></>;
                })}
              </EnvironmentTagsContainer>
            </ItemInputContainer>
          );
        })}
      </Stack>
      {isConfigItemSelected && (
        <SelectedConfigItem
          {...props}
          currentConfigurationItem={selectedSectionItem}
        />
      )}
    </MainContainer>
  );
}

export default ConfigurationItem;
