import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

import {
  getPendingConfigurationItemsList,
  getSelectedSiteSection
} from "../../redux/selectors/siteSelectors";
import {
  SiteToDuplicateOrCreateType,
  updatePendingChangesList
} from "./siteSlice";
import { useGetConfigurationItemsListByConfigurationQuery } from "../../redux/api/site";

import ConfigurationItem from "./ConfigurationItem";
import { getPendingChangeConfigurationId } from "../../lib/siteHelpers";
import { useAppDispatch } from "../../store";

const MainContainer = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  padding: "8px 0px 12px 0px",
  gap: 20,
  overflow: "scroll",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  borderRadius: 10
}));

const ItemsContainer = styled(Stack)(() => ({
  flexDirection: "column",
  gap: "15px",
  margin: "0 10px"
}));

export const LoadingSpinner = (props: { size?: number; color?: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1
      }}
    >
      <CircularProgress
        color="primary"
        size={props.size || 40}
        sx={{
          color: props.color || "primary.main"
        }}
      />
    </Box>
  );
};

export type SelectedConfigurationItemType = {
  configurationName: string;
  configurationItemId: string;
  originalValues: ConfigurationValue[];
  currentSelectedConfigValue: ConfigurationItemType | null;
};

type ConfigurationItemsPropsType = {
  siteToDuplicateOrCreate: SiteToDuplicateOrCreateType | null;
};

function ConfigurationItems(props: ConfigurationItemsPropsType) {
  const { siteToDuplicateOrCreate } = props;
  const {
    allSiteConfigurationsByConfigurationId,
    generalSiteInformation,
    isSiteCreationPending
  } = siteToDuplicateOrCreate || {};
  const isInNewSiteCreatingMode = !!generalSiteInformation?.siteId;

  const dispatch = useAppDispatch();
  const pendingConfigurationChangesList = useSelector(
    getPendingConfigurationItemsList
  );
  const hasAnyPendingChanges = !!Object.keys(pendingConfigurationChangesList)
    .length;

  const selectedSiteSection = useSelector(getSelectedSiteSection);
  const { name: configurationName, id: sectionId } = selectedSiteSection;
  const duplicateOrCreateSiteConfigItems =
    allSiteConfigurationsByConfigurationId?.[sectionId]?.configurationItems ||
    [];
  const [selectedConfigurationItem, setSelectedConfigurationItem] =
    useState<SelectedConfigurationItemType>({
      configurationName: "",
      configurationItemId: "",
      originalValues: [],
      currentSelectedConfigValue: null
    });

  const { data, isLoading } = useGetConfigurationItemsListByConfigurationQuery(
    {
      configurationId: sectionId
    },
    {
      skip: !sectionId || isInNewSiteCreatingMode,
      refetchOnMountOrArgChange: true
    }
  );

  useEffect(() => {
    const myDiv = document.getElementById("config-items-section");

    if (myDiv?.scrollTo) {
      myDiv.scrollTop = 0;
    }
  }, [selectedSiteSection]);

  useEffect(() => {
    return () => {
      clearSelectedConfigItem();
    };
  }, []);

  const updateSelectedConfigurationItem = (
    value: SelectedConfigurationItemType
  ) => {
    setSelectedConfigurationItem(value);
  };
  const clearSelectedConfigItem = () => {
    updateSelectedConfigurationItem({
      configurationName: "",
      configurationItemId: "",
      originalValues: [],
      currentSelectedConfigValue: null
    });
  };
  const saveSelectedConfigurationItemChanges = (
    pendingChange: PendingChangeType
  ) => {
    dispatch(
      updatePendingChangesList({
        ...pendingChange,
        sectionName: selectedSiteSection.name,
        sectionId: selectedSiteSection.id
      })
    );
    clearSelectedConfigItem();
  };

  if (isLoading || (!data && !isInNewSiteCreatingMode) || isSiteCreationPending)
    return <LoadingSpinner />;

  const configurationItemsList = isInNewSiteCreatingMode
    ? duplicateOrCreateSiteConfigItems
    : (data && data.configurationItems) || [];
  const currentSectionId = (data && data.configurationId) || sectionId;

  return (
    <MainContainer
      id={`config-items-section`}
      sx={{
        gridColumn: "2/3"
      }}
    >
      <Box
        sx={{
          marginTop: "15px",
          marginLeft: "10px"
        }}
      >
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 400
          }}
        >
          {configurationName}
        </Typography>
      </Box>
      <ItemsContainer>
        {configurationItemsList.map((item) => {
          const { configurationItemId, name } = item;

          const possiblePendingConfigurationItemId =
            getPendingChangeConfigurationId(configurationItemId, sectionId);
          const possiblePendingChangeConfiguration =
            pendingConfigurationChangesList[possiblePendingConfigurationItemId];

          const isConfigurationItemPartOfPendingChanges =
            !!possiblePendingChangeConfiguration;
          const isCurrentSectionConfigurationSelected =
            currentSectionId === possiblePendingChangeConfiguration?.sectionId;

          const configurationItem =
            isConfigurationItemPartOfPendingChanges &&
            isCurrentSectionConfigurationSelected
              ? {
                  ...item,
                  configurationValues:
                    possiblePendingChangeConfiguration.currentConfigurationItemValues
                }
              : item;
          return (
            <ConfigurationItem
              selectedSectionItem={configurationItem}
              key={`item-${configurationItemId}-${name}`}
              selectedConfigurationItem={selectedConfigurationItem}
              saveSelectedConfigurationItemChanges={
                saveSelectedConfigurationItemChanges
              }
              isPartOfPendingChangesList={
                isConfigurationItemPartOfPendingChanges &&
                isCurrentSectionConfigurationSelected
              }
              clearSelectedConfigItem={clearSelectedConfigItem}
              updateSelectedConfigurationItem={updateSelectedConfigurationItem}
              isInNewSiteCreatingMode={isInNewSiteCreatingMode}
            />
          );
        })}
      </ItemsContainer>
    </MainContainer>
  );
}

export default ConfigurationItems;
