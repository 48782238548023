import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Stack, Typography, styled } from "@mui/material";

import loginImg from ".././assets/images/loginPage.webp";
import AutostoreLogo from "../assets/images/as-logo.svg";

export const MainContainer = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "2fr 3fr",
  height: "100vh",
  width: "100vw"
}));

export const LoginBtn = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2),
  width: 210,
  fontSize: 20,
  borderRadius: "15px",
  marginTop: theme.spacing(4)
}));

export const SecondaryContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  marginLeft: theme.spacing(8),
  justifyContent: "center",
  gap: 2
}));

export const LogoImage = styled("img")(() => ({
  position: "absolute",
  height: "50px",
  width: "150px",
  top: "35px"
}));

export const LoginImage = styled("img")(() => ({
  objectFit: "cover",
  height: "100%",
  width: "100%"
}));

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <MainContainer>
      <SecondaryContainer>
        <LogoImage alt="autostore logo" src={AutostoreLogo} />
        <Typography variant="h4" fontWeight={300}>
          Welcome to
        </Typography>
        <Typography variant="h2" fontWeight={500}>
          Fulfillment Center Configuration
        </Typography>
        <LoginBtn
          color="primary"
          variant="contained"
          onClick={() =>
            setTimeout(() => {
              loginWithRedirect();
            }, 0)
          }
        >
          Login
        </LoginBtn>
      </SecondaryContainer>
      <LoginImage alt="client configuration login image" src={loginImg} />
    </MainContainer>
  );
};

export default Login;
