import { useState } from "react";

import SearchBar, { OptionType } from "../input/SearchBar";
import { useGetSitesListPreviewQuery } from "../../redux/api/site";
import { navigateToRecentSiteSearch } from "../drawer/mainCustomDrawerSlice";
import { useAppDispatch } from "../../store";

export const formatSitesPreviewList = (
  sitesList: GetFormattedSitesListPreviewResponse
) => {
  return sitesList.map((el) => {
    const { siteId, siteName, clientId, clientName } = el;
    return {
      label: siteName || "",
      id: siteId,
      additionalData: {
        clientId,
        clientName
      }
    };
  });
};

const SitesSearchBar = () => {
  const [selectedInputValue, setSelectedInputValue] = useState({
    siteId: "",
    siteName: "",
    clientId: "",
    clientName: ""
  });
  const [searchText, setSearchText] = useState("");
  const dispatch = useAppDispatch();

  const sitesListResponse = useGetSitesListPreviewQuery(undefined, {
    refetchOnMountOrArgChange: true
  });
  const sitesList = (sitesListResponse.data ||
    []) as unknown as GetFormattedSitesListPreviewResponse;
  const sitesSearchbarOptions = formatSitesPreviewList(sitesList);

  const resetSearchbarState = () => {
    setSelectedInputValue({
      siteId: "",
      siteName: "",
      clientId: "",
      clientName: ""
    });
    setSearchText("");
  };

  const setSelectedSite = (site: OptionType | null) => {
    if (!site) {
      resetSearchbarState();
      return;
    }
    if (site.additionalData) {
      const clientData = site.additionalData as {
        clientId: string;
        clientName: string;
      };
      setSelectedInputValue({
        siteId: site.id,
        siteName: site.label,
        clientId: clientData.clientId,
        clientName: clientData.clientName
      });
      setSearchText(site.label);
    }
  };
  const setAutocompleteInputValue = (inputValue: string) => {
    setSearchText(inputValue);
  };

  const onEnterBtnClick = () => {
    dispatch(navigateToRecentSiteSearch(selectedInputValue));
  };

  return (
    <SearchBar
      isEnterBtnDisabled={!selectedInputValue.siteId}
      inputPlaceholder="Select an FC"
      options={sitesSearchbarOptions}
      onSelect={setSelectedSite}
      onInputValueChange={setAutocompleteInputValue}
      inputValue={searchText}
      autocompleteMatcher={(option, value) => {
        return option.id == value.id;
      }}
      onEnterBtnClick={onEnterBtnClick}
    />
  );
};

export default SitesSearchBar;
