import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import {
  EnvironmentTag,
  EnvironmentTagsContainer,
  renderEnvTag
} from "./ConfigurationItem";
import ItemInput from "../input/ItemInput";
import useValidationRulesPerEnvironment from "../../hooks/useValidationRulesPerEnvironment";
import { generateUUID } from "../../lib/helpers";
import { useGetEnvironmentsListQuery } from "../../redux/api/environments";
import { Stack } from "@mui/material";

type PendingConfigurationItemValuesChangeType = {
  pendingConfigurationIteValuesListChanges: ConfigurationValue[];
  configurationName: string;
  type: "original" | "new";
  dataType: ConfigurationItemDataType;
  validationRules: ValidationRuleType[];
};

const MainContainer = styled(Stack)(() => ({
  flexDirection: "column",
  alignItems: "flex-end"
}));

function PendingConfigurationItemValuesChange(
  props: PendingConfigurationItemValuesChangeType
) {
  const {
    pendingConfigurationIteValuesListChanges,
    configurationName,
    type,
    dataType,
    validationRules
  } = props;
  const isOriginalvalue = type === "original";

  const { data } = useGetEnvironmentsListQuery(undefined);
  const environmentsData = (data || {}) as EnvironmentsListType;

  const { environmentsDetailsById = {} }: EnvironmentsListType =
    environmentsData;

  const { validationRulesPerEnvironment, allAvailableEnvironmentNames } =
    useValidationRulesPerEnvironment(validationRules, environmentsDetailsById);

  return (
    <Box>
      {pendingConfigurationIteValuesListChanges.map((configItem, index) => {
        const { environmentIds, configurationValue } = configItem;
        const currentValidationRules =
          validationRulesPerEnvironment[environmentIds[0]];
        const validationRule = currentValidationRules?.validationRules || null;
        const isRequired = currentValidationRules?.isRequired || false;

        return (
          <MainContainer
            key={`old-config-item-value-${configurationName}${index}`}
          >
            <ItemInput
              configurationValue={configurationValue}
              dataType={dataType}
              isDisabled={isOriginalvalue}
              readOnly={!isOriginalvalue}
              required={isRequired}
              validationRules={validationRule}
            />
            <EnvironmentTagsContainer
              data-testid="environments-checkbox-list"
              sx={{ alignSelf: "center", padding: "0px" }}
            >
              {allAvailableEnvironmentNames.map((envName) => {
                if (
                  environmentIds.find(
                    (envId: string) =>
                      envName ===
                      environmentsDetailsById?.[envId]?.environmentName
                  )
                )
                  return renderEnvTag(envName, isOriginalvalue);
                return (
                  <EnvironmentTag
                    key={`env-${envName || generateUUID()}`}
                    extraProps={{
                      tagColor: "none",
                      width: 32,
                      height: 22,
                      isPartOfPendingChangesList: false
                    }}
                  />
                );
              })}
            </EnvironmentTagsContainer>
          </MainContainer>
        );
      })}
    </Box>
  );
}

export default PendingConfigurationItemValuesChange;
